<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span> {{ title }} </span>
        </h5>

        <div class="row mt-3">
          <div class="col-md-6 py-0">
            <!-- <b-form-group
              id="input-group-title"
              label="Nama Warga:"
              label-for="input-title"
            >
              <b-form-input
                id="input-name"
                v-model="form.citizen_name"
                readonly
              ></b-form-input>
              <small>Nama warga dari akun yang digunakan</small>
            </b-form-group> -->

            <b-form-group id="input-group-title">
              <label class="font-weight-bold" for="input-nik"><b>NIK:</b></label>
              <b-form-input
                class="input-custom"
                id="input-nik"
                v-model="getNik"
                placeholder="NIK"
              ></b-form-input>
              <h5 class="validate-custom mt-2" v-if="form.citizen_name != ''">
                Nama Penduduk : {{ form.citizen_name }}
              </h5>
              <small class="text-danger">
                {{ error.citizen_id }}
              </small>
            </b-form-group>
          </div>
          <div class="col-md-6 py-0">
            <b-form-group id="input-group-category">
              <label class="font-weight-bold"><b>Kategori Pengaduan:</b></label>
                <b-form-select
                class="input-custom"
                id="input-category"
                v-model="form.category_id"
                :options="report_category"
                @input="filterByCategory"
              ></b-form-select>
              <!-- <h6 class="text-danger mt-1 validate-custom">
                {{ error.category_id }}
              </h6>
              <treeselect
                v-model="form.category_id"
                :multiple="false"
                placeholder="Pilih Kategori"
                :options="report_category"
                @input="filterByCategory"
              /> -->
              <small class="text-danger">{{ error.category_id }}</small>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="input-group-title">
          <label class="font-weight-bold" for="input-title"><b>Judul Pengaduan:</b></label>
          <b-form-input
            id="input-title"
            v-model="form.title"
            placeholder="Judul Pengaduan"
          ></b-form-input>
          <small class="text-danger">{{ error.title }}</small>
        </b-form-group>

        <b-form-group id="input-group-description">
          <label class="font-weight-bold" for="input-description"
            ><b>Deskripsi:</b></label
          >
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            placeholder="Deskripsi"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.description }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button
            type="submit"
            variant="primary"
            disabled
            v-if="citizen_registred_status"
            >Kirim</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            v-if="citizen_registred_status == 0"
            >Kirim</b-button
          >
          <!-- <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/citizen-report/list')"
          >
            Batal
          </b-button> -->
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        title: "",
        category_id: "",
        description: "",
        citizen_id: "",
      },

      report_category: {},
      timeout: null,
      citizen_registred_status: 0,
    };
  },
  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/citizen-report/list");
      }
    },
    filterByCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.category_id = "";
      }
      // this.pagination();
    },
    async getCategoryOption() {
     let response = await module.setSelectOption("category-citizen-report");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.report_category = response.data;
        this.report_category.unshift({
          text: "Pilih Jenis",
          value: "",
          disabled: true,
        });
      }
    },

    async pagination() {
      this.form.citizen_name = "";
      this.error.citizen_id = "";

      let response = await module.get(
        `citizen-by-nik/${this.form.citizen_number}`
      );

      if (response) {
        this.citizen_registred_status = 0;
        let res = response;
        this.form.citizen_id = res.id;
        this.form.citizen_name = res.name;
      } else {
        this.citizen_registred_status = 1;
        this.error.citizen_id =
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.";

        Swal.fire(
          "",
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.",
          "error"
        );
      }
    },
  },
  computed: {
    getNik: {
      get() {
        return this.form.citizen_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.citizen_number = val;
          this.form.citizen_id = val;
          if (val.length >= 16) {
            this.pagination();
          }
        }, 500);
      },
    },
  },
  mounted() {
    this.getCategoryOption();
  },
};
</script>

<style scoped>
.form-control-lg {
  height: 61px;
}
.form-control{
  border-color: rgb(29, 172, 116);
}
.form-control:focus{
  border-color: #2989fe;
}
.input-custom {
  /* font-size: 25px; */
  border-color: rgb(29, 172, 116);
}
.input-custom:focus {
  border-color: #2989fe;
}
</style>
